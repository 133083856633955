<template>
  <div class="box">
    <Header>过往专辑</Header>
    <div style="flex: 1; overflow: auto">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        offset="50"
        @load="onLoad"
        :immediate-check="false"
      >
        <div
          class="box1"
          @click="$router.push(`/albumDetail?id=${item.id}`)"
          v-for="item in albumList"
          :key="item.id"
        >
          <img :src="item.pic" alt="" />
          <div class="title">{{ item.name }}</div>
          <div class="subTitle">{{ item.describe }}</div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      albumList: [],
      goodsListParam: {
        pageindex: 1,
        pagesize: 20,
      },
      isInitLoading: true,
      loadMore: true,
      loading: false,
      refreshing: false,
      finished: false,
    };
  },
  mounted() {
    this.isInitLoading = true;
    this.getAlbum();
  },
  methods: {
    onRefresh() {
      this.albumList = [];
      this.goodsListParam.pageindex = 1;
      this.goodsListParam.pagesize = 20;
      this.loadMore = true;
      this.finished = false;
      this.getAlbum();
    },
    onLoad() {
      if (this.refreshing) {
        this.refreshing = false;
      }
      this.goodsListParam.pageindex += 1;
      this.loading = true;
      if (this.loadMore) {
        this.getAlbum();
      } else {
        this.finished = true;
      }
    },
    getAlbum() {
      let token = Object.assign({ key: "isAlbum" }, this.goodsListParam);
      token.token = localStorage.getItem("token");
      this.$api.goodsAlbumList(token).then(
        (res) => {
          this.isInitLoading = false;
          setTimeout(() => {
            this.refreshing = false;
          }, 1000);
          this.loading = false;
          if (res.code == 0) {
            if (res.data.length === 0) {
              this.loadMore = false;
              this.goodsListParam.pageindex -= 1;
            }
            this.albumList = this.albumList.concat(res.data);
          }
        },
        () => {
          this.isInitLoading = false;
        }
      );
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
.box1 {
  width: 341px;
  margin: 0 auto 30px;
  border-radius: 10px;
  box-shadow: 0px 0px 14px 5px rgba(1, 15, 45, 0.05);
  padding-bottom: 14px;
  overflow: hidden;
  img {
    width: 100%;
    height: 200px;
    vertical-align: middle;
  }
  .title {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #000000;
    padding: 13px 13px;
  }
  .subTitle {
    padding: 0 13px;
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 500;
    line-height: 20px;
    color: #999999;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -moz-box;
    -moz-line-clamp: 2;
    -moz-box-orient: vertical;
    overflow-wrap: break-word;
    word-break: break-all;
    white-space: normal;
    overflow: hidden;
  }
}
</style>